// src/context/AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../firebaseConfig'; 
import { onAuthStateChanged, setPersistence, browserLocalPersistence } from 'firebase/auth';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    // Set persistence to local
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        console.log("Persistence set to local");
      })
      .catch((error) => {
        console.error("Error setting persistence:", error);
      });

    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log("Auth state changed:", user); // Log the user object
      setCurrentUser(user); // Update state
    });

    return () => {
      console.log("Cleaning up listener.");
      unsubscribe(); // Cleanup the listener on unmount
    };
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};
