import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  GoogleAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { FcGoogle } from 'react-icons/fc';
import Layout from './Layout';
import { useAuth } from './AuthContext';

const AuthPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // For sign-up
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSigningIn, setIsSigningIn] = useState(true); // Toggle between sign-in and sign-up
  const [isResettingPassword, setIsResettingPassword] = useState(false); // Toggle Reset Password mode
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate('/QuiltBuilder'); // Redirect to QuiltBuilder if already logged in
    }
  }, [currentUser, navigate]);

  // Google Sign-In Function
  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      navigate('/QuiltBuilder'); // Redirect to QuiltBuilder after successful login
    } catch (error) {
      setErrorMessage('Failed to sign in with Google. Please try again.');
      console.error('Google Sign-In Error:', error);
    }
  };

  // Email Sign-Up Function
  const handleEmailSignUp = async () => {
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }

    try {
      await createUserWithEmailAndPassword(auth, email, password);
      navigate('/QuiltBuilder'); // Redirect after successful sign-up
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setErrorMessage('An account with this email already exists. Please Sign In instead.');
        setIsSigningIn(true); // Automatically switch to Sign In mode
      } else {
        setErrorMessage('Failed to sign up. Please try again.');
      }
      console.error('Sign-Up Error:', error);
    }
  };

  // Email Sign-In Function
  const handleEmailSignIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/QuiltBuilder'); // Redirect after successful sign-in
    } catch (error) {
      if (error.code === 'auth/wrong-password') {
        setErrorMessage('The password you entered is incorrect. Please try again.');
      } else if (error.code === 'auth/user-not-found') {
        setErrorMessage('No account found with this email. Please Sign Up first.');
      } else {
        setErrorMessage('Failed to sign in. Please check your credentials and try again.');
      }
      console.error('Sign-In Error:', error);
    }
  };

  // Reset Password Function
  const handleResetPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setSuccessMessage('Password reset email sent! Check your inbox and return here to log in.');
      setErrorMessage(''); // Clear previous error message
      setTimeout(() => {
        setIsResettingPassword(false); // Return to sign-in mode after 3 seconds
      }, 3000);
    } catch (error) {
      if (error.code === 'auth/user-not-found') {
        setErrorMessage('No account found with this email. Please check your email address.');
      } else {
        setErrorMessage('Failed to send password reset email. Please try again.');
      }
      console.error('Password Reset Error:', error);
    }
  };

  return (
    <Layout>
      <div className="flex items-center justify-center min-h-screen bg-[#F6E8E0] px-4">
        <div className="bg-zinc-300 shadow-md rounded-lg p-6 sm:p-8 w-full max-w-[400px]">
          <p className="text-lg text-[#40302e] mb-6 text-center font-medium" style={{ fontFamily: 'Playfair Display' }}>
            {isResettingPassword
              ? 'Reset Your Password'
              : isSigningIn
              ? 'Sign in to Your Account'
              : 'Create Your Account'}
          </p>

          {/* Display Error Message */}
          {errorMessage && <p className="text-red-500 text-center mb-4">{errorMessage}</p>}

          {/* Display Success Message */}
          {successMessage && <p className="text-green-500 text-center mb-4">{successMessage}</p>}

          {isResettingPassword ? (
            <>
              {/* Email Input for Reset Password */}
              <input
                type="email"
                placeholder="Enter your registered email"
                className="border border-gray-300 rounded-lg px-4 py-3 w-full mb-4 focus:outline-none focus:ring-2 focus:ring-[#a07545] focus:border-transparent"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              {/* Reset Password Button */}
              <button
                className="bg-blue-500 text-white font-medium rounded-lg w-full py-3 mb-4 hover:bg-blue-600 transition duration-200"
                onClick={handleResetPassword}
                style={{ fontFamily: 'Playfair Display' }}
              >
                Send Reset Email
              </button>

              {/* Back to Sign In */}
              <p className="text-sm text-gray-500 mt-4 text-center">
                Remembered your password?{' '}
                <span
                  onClick={() => setIsResettingPassword(false)}
                  className="text-[#a07545] underline cursor-pointer"
                >
                  Sign In
                </span>
              </p>
            </>
          ) : (
            <>
              {/* Google Sign-In Button */}
              <button
                className="border border-gray-300 text-gray-700 font-medium rounded-lg flex items-center justify-center w-full py-3 mb-4 bg-white hover:bg-gray-100"
                onClick={signInWithGoogle}
              >
                <FcGoogle className="text-2xl mr-2" /> Continue with Google
              </button>

              {/* Divider */}
              <p className="text-sm text-gray-500 mb-4 text-center" style={{ fontFamily: 'Playfair Display' }}>
                OR
              </p>

              {/* Email Input */}
              <input
                type="email"
                placeholder="Email"
                className="border border-gray-300 rounded-lg px-4 py-3 w-full mb-4 focus:outline-none focus:ring-2 focus:ring-[#a07545] focus:border-transparent"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              {/* Password Input */}
              <input
                type="password"
                placeholder="Password"
                className="border border-gray-300 rounded-lg px-4 py-3 w-full mb-4 focus:outline-none focus:ring-2 focus:ring-[#a07545] focus:border-transparent"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              {/* Confirm Password Input for Sign-Up */}
              {!isSigningIn && (
                <input
                  type="password"
                  placeholder="Confirm Password"
                  className="border border-gray-300 rounded-lg px-4 py-3 w-full mb-4 focus:outline-none focus:ring-2 focus:ring-[#a07545] focus:border-transparent"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              )}

              {/* Sign In/Sign Up Button */}
              <button
                className="bg-blue-500 text-white font-medium rounded-lg w-full py-3 mb-4 hover:bg-blue-600 transition duration-200"
                onClick={isSigningIn ? handleEmailSignIn : handleEmailSignUp}
                style={{ fontFamily: 'Playfair Display' }}
              >
                {isSigningIn ? 'Sign In with Email' : 'Sign Up with Email'}
              </button>

              {/* Forgot Password */}
              {isSigningIn && (
                <p className="text-sm text-gray-500 mt-4 text-center">
                  <span
                    onClick={() => setIsResettingPassword(true)}
                    className="text-[#a07545] underline cursor-pointer"
                  >
                    Forgot Password?
                  </span>
                </p>
              )}

              {/* Toggle Between Sign-In and Sign-Up */}
              <p className="text-sm text-gray-500 mt-4 text-center">
                {isSigningIn
                  ? "Don't have an account?"
                  : 'Already have an account?'}{' '}
                <span
                  onClick={() => setIsSigningIn(!isSigningIn)}
                  className="text-[#a07545] underline cursor-pointer"
                >
                  {isSigningIn ? 'Sign Up' : 'Sign In'}
                </span>
              </p>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default AuthPage;
