import React from 'react';
import quilt1 from '../Assets/quilt1.webp';
import quilt2 from '../Assets/quilt2.webp';
import { useNavigate } from 'react-router-dom';

const DesignQuilt = () => {
  const navigate = useNavigate();

  const handleStartDesigningClick = () => {
    navigate('/sign-in'); // Redirects to the sign-in page
  };

  return (
    <section className="flex flex-col items-center gap-10 w-full max-w-[1257px] px-5 mt-0">
      {/* Image and Button Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 justify-center items-center w-full">
        {/* First Quilt Image and Text */}
        <div className="flex flex-col items-center gap-4">
          <div
            className="overflow-hidden py-3 px-5 text-lg leading-6 text-zinc-800 bg-[#F6E8E0] rounded-md max-w-[400px] text-center -mt-8"
            style={{ fontFamily: "Playfair Display" }}
          >
            NOSTAi crafts... <br />
            designs that preserve memories <br />
            designs that share your story
          </div>
          <img
            loading="lazy"
            src={quilt1}
            alt="AI generated quilt design"
            className="object-contain w-[600px] h-[600px] mt-8"
          />
       
        </div>

        {/* Second Quilt Image and Button */}
        <div className="flex flex-col items-center gap-4">
          <button
            onClick={handleStartDesigningClick}
            style={{ fontFamily: "Playfair Display" }}
            className="px-10 py-4 text-xl font-medium text-black border border-black border-solid bg-zinc-300 bg-opacity-50 rounded-full max-md:px-8 max-md:py-3 max-md:text-lg"
          >
            DESIGN YOUR QUILT
          </button>
          <img
            loading="lazy"
            src={quilt2}
            alt="AI generated quilt design"
            className="object-contain w-[600px] h-[600px] mt-8"
          />

        </div>
      </div>
    </section>
  );
};

export default DesignQuilt;
