import React from 'react';

const AboutSection = () => {
  return (
    <section className="flex flex-col justify-center w-full max-w-[994px] px-5 mx-auto">
      <h2 className="text-2xl tracking-wider leading-10 text-black text-center mb-5 max-md:text-lg " style={{ fontFamily: "Playfair Display" }}>
        What is NOSTAi for?
      </h2>
      <p className="text-lg tracking-wide leading-8 text-black text-center mb-10 max-md:text-base" style={{ fontFamily: "Playfair Display" }}>
        NOSTAi is for those who value memories. <br />
        It's for those who want to preserve their stories with the touch of technology. <br />
        For people who believe that the best memories deserve to be crafted with care. <br />
        They know, a thoughtful design brings more than nostalgia—it creates lasting connections.
      </p>
     
    </section>
  );
};

export default AboutSection;
