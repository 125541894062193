// src/components/SignOut.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth'; // Import Firebase signOut function
import { auth } from '../firebaseConfig'; // Import the Firebase authentication configuration

const SignOut = () => {
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOut(auth); // Sign out the user from Firebase
      console.log('User signed out');
      navigate('/'); // Redirect to home page after sign-out
    } catch (error) {
      console.error('Error during sign-out:', error);
    }
  };

  return (
    <button
      onClick={handleSignOut}
      className="text-sm sm:text-base text-black hover:underline" style={{ fontFamily: "Playfair Display" }}
    >
      Sign Out
    </button>
  );
};

export default SignOut;
