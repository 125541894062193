import React from 'react';
import howitworks from '../Assets/howitworks.webp';
import generated from '../Assets/generated.webp';

const HowItWorks = () => {
  return (
    <section className="flex flex-col items-center gap-10 py-14 bg-[#F6E8E0] mt-[-50px] mb-0 w-full max-w-[1257px] px-4">
      <div className="grid grid-cols-1 md:grid-cols-[1fr_1fr] gap-10 md:gap-20 items-start">
        {/* Left Column: Title and Image */}
        <div className="flex flex-col items-center">
          <h2
            className="text-xl md:text-2xl font-bold text-gray-700 mb-4 text-center md:text-left"
            style={{ fontFamily: "Playfair Display" }}
          >
            How It Works
          </h2>
          <img
            src={howitworks}
            alt="Choose Grid"
            className="w-[600px] h-[600px] md:w-[600px] md:h-[600px] lg:w-[600px] lg:h-[600px] object-contain"
          />
        </div>

        {/* Right Column: Second Image */}
        <div className="flex flex-col items-center justify-start">
          <div className="h-[40px] hidden md:block" />
          <img
            src={generated}
            alt="Generated Quilt Example"
            className="w-[600px] h-[600px] md:w-[600px] md:h-[600px] lg:w-[600px] lg:h-[600px] object-contain"
          />
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
