import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../Assets/Logo.png';
import SignOut from './SignOut'; 
import Footer from '../home/Footer'; // Import your Footer component

const QuiltBuilderWithLayout = ({ handleReset, children, isGenerated }) => { // Added isGenerated as a prop
    const navigate = useNavigate();

    return (
        <div className="min-h-screen flex flex-col bg-white">
            {/* Header */}
            <header className="fixed top-0 w-full bg-zinc-300 py-6 shadow-md z-50">
                <div className="container mx-auto flex justify-between items-center px-4 sm:px-10">
                    {/* Logo */}
                    <div className="flex items-center">
                        <img 
                            src={logo} 
                            onClick={() => navigate('/')} 
                            alt="Logo" 
                            className="w-50 h-10 mr-2 cursor-pointer"
                        />
                    </div>

                    {/* Navigation */}
                    <nav className="space-x-4 sm:space-x-8 flex items-center">
                        <button
                            onClick={handleReset}
                            className="text-sm sm:text-base text-black hover:underline"
                            style={{ fontFamily: "Playfair Display" }}
                        >
                            {isGenerated ? "New" : "Reset"} {/* Dynamically change button text */}
                        </button>
                        <SignOut />
                    </nav>
                </div>
            </header>

            {/* Page Content */}
            <main className="flex-grow mt-16 sm:mt-20 px-4 sm:px-10 bg-[#F6E8E0]">
                {children} {/* Render the page content */}
            </main>

            {/* Footer */}
            <Footer />
        </div>
    );
};

export default QuiltBuilderWithLayout;
