import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../Assets/Logo.png';
import Footer from '../home/Footer'; // Import your Footer component

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current route





  return (
    <div className="min-h-screen flex flex-col bg-white">
      {/* Header */}
      <header className="fixed top-0 w-full bg-zinc-300 py-6 shadow-md z-50">
        <div className="container mx-auto flex justify-between items-center px-4 sm:px-10">
          <div className="flex items-center">
            <img
              src={logo}
              alt="Logo"
              onClick={() => navigate('/')}
              className="w-50 h-10 mr-2"
            />
            <h1
              onClick={() => navigate('/')}
              className="text-2xl font-bold text-slate-800 cursor-pointer"
            ></h1>
          </div>
        </div>
      </header>

      {/* Page Content */}
      <div className="flex-grow mt-[4rem]">
        {children} {/* Render the page content */}
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Layout;
