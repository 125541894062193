import React from 'react';
import { useNavigate } from 'react-router-dom';


const StatItem = ({ value, label }) => (
  <div className="flex flex-col items-center w-full">
    <div className="text-2xl font-semibold tracking-wider text-center text-black max-md:mt-5">
      {value}
      <br />
      <span className="text-lg font-normal">{label}</span>
    </div>
  </div>
);

const Statistics = () => {
  const navigate = useNavigate();



const handleStartDesigningClick = () => {
  navigate('/sign-in'); // Redirects to the sign-in page
};
  const stats = [
    { value: "50%", label: "Increase in Orders" },
    { value: "100+", label: "Local Artisans" },
    { value: "1000+", label: "Memories Preserved" },
  ];

  return (
    <section className="flex flex-col items-center gap-5 mt-20 w-full max-md:mt-10 px-5">
      {/* Statistics Section */}
      <div className="grid grid-cols-3 gap-5 px-5 py-4 rounded-2xl bg-zinc-300  max-w-[700px] w-full max-md:grid-cols-1" style={{ fontFamily: "Playfair Display" }}>
        {stats.map((stat, index) => (
          <StatItem key={index} {...stat} />
        ))}
      </div>

      {/* Button Section */}
      <button onClick={handleStartDesigningClick} style={{ fontFamily: "Playfair Display" }} className="px-10 py-4 mt-5 text-xl font-medium text-black border border-black border-solid bg-zinc-300 bg-opacity-50 rounded-full max-md:px-8 max-md:py-3 max-md:text-lg">
        DESIGN YOUR QUILT
      </button>
    </section>
  );
};

export default Statistics;
