import React, { useState, useRef, useEffect } from 'react'; 
import { storage } from '../firebaseConfig'; // Import Firebase Storage configuration
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject,listAll } from "firebase/storage"; // Import Firebase Storage functions
import loadingGif from '../Assets/loading.gif';
import QuiltBuilderWithLayout from './QuiltBuilderWithLayout';
import { analytics,db } from '../firebaseConfig';
import { getAnalytics, logEvent } from "firebase/analytics";
import { collection, addDoc,onSnapshot,query, orderBy,deleteDoc } from 'firebase/firestore';
import QuiltEditor from './QuiltEditor'; // Import QuiltEditor component
import PaymentGateway from './PaymentGateway';
import { doc, setDoc, getDoc } from "firebase/firestore"; // Firestore functions
import { useAuth } from '../components/AuthContext';
import { updateDoc } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
import logoSrc from '../Assets/favicon-32x32.png'
import sweetroot from '../Assets/sweetroot.jpeg'
import memorystitch from '../Assets/memorystitch.png'
import project from '../Assets/projectrepat.jpeg'



import { FaWhatsapp, FaFacebookF, FaTwitter, FaPinterestP, FaInstagram } from "react-icons/fa"; // Import icons

const ShareModal = ({ shareLink, setShowModal }) => {
    const [isCopied, setIsCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(shareLink);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 3000);
    };

    const openShareLink = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg p-6 w-full max-w-md shadow-lg">
                <h2 className="text-lg font-medium mb-4" style={{ fontFamily: "Playfair Display" }}>
                    {shareLink ? "Public Link Created" : "Creating Link..."}
                </h2>
                {shareLink ? (
                    <>
                        <p className="text-sm text-gray-700 mb-4" style={{ fontFamily: "Playfair Display" }}>
                            Share your quilt design with this link:
                        </p>
                        <div className="flex items-center mb-4">
                            <input
                                type="text"
                                value={shareLink}
                                readOnly
                                className="border border-gray-300 rounded-l-lg px-3 py-2 flex-1 text-sm"
                            />
                            <button
                                onClick={copyToClipboard}
                                className={`px-4 py-2 rounded-r-lg ${
                                    isCopied
                                        ? "bg-green-500 text-white"
                                        : "bg-blue-500 text-white hover:bg-blue-600"
                                }`}
                            >
                                {isCopied ? "Link Copied" : "Copy Link"}
                            </button>
                        </div>

                        {/* Social Media Share Buttons */}
                        <div className="flex justify-center space-x-4 mt-4">
                            {/* WhatsApp */}
                            <button
                                onClick={() =>
                                    openShareLink(
                                        `https://wa.me/?text=${encodeURIComponent(
                                            `Check out this quilt design: ${shareLink}`
                                        )}`
                                    )
                                }
                                className="text-green-500 hover:text-green-600 text-2xl"
                                title="Share on WhatsApp"
                            >
                                <FaWhatsapp />
                            </button>
                            {/* Facebook */}
                            <button
                                onClick={() =>
                                    openShareLink(
                                        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                                            shareLink
                                        )}`
                                    )
                                }
                                className="text-blue-600 hover:text-blue-700 text-2xl"
                                title="Share on Facebook"
                            >
                                <FaFacebookF />
                            </button>
                            
                            
                            {/* Pinterest */}
                            <button
                                onClick={() =>
                                    openShareLink(
                                        `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(
                                            shareLink
                                        )}&description=${encodeURIComponent(
                                            "Check out this amazing quilt design!"
                                        )}`
                                    )
                                }
                                className="text-red-500 hover:text-red-600 text-2xl"
                                title="Share on Pinterest"
                            >
                                <FaPinterestP />
                            </button>
                            {/* Instagram */}
                            <button
                                onClick={() =>
                                    alert("Instagram does not support direct link sharing. Please copy the link!")
                                }
                                className="text-pink-500 hover:text-pink-600 text-2xl"
                                title="Share on Instagram"
                            >
                                <FaInstagram />
                            </button>
                        </div>
                    </>
                ) : (
                    <p className="text-sm text-gray-700 mb-4" style={{ fontFamily: "Playfair Display" }}>
                        Please wait while your link is being generated...
                    </p>
                )}
                <div className="flex justify-end">
                    <button
                        onClick={() => setShowModal(false)}
                        className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400"
                        style={{ fontFamily: "Playfair Display" }}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};








function QuiltBuilder() {
    const [uploadedImages, setUploadedImages] = useState([]);
    const [gridSize, setGridSize] = useState(''); // Default no selection for grid size
    const [generated, setGenerated] = useState(false); // Flag to indicate when to generate quilt layout
    const [requiredImages, setRequiredImages] = useState(0); // Number of images required
    const [showWarning, setShowWarning] = useState(''); // State for controlling the warning visibility
    const fileInputRef = useRef(null); // For accessing the file input
    const [quiltImageUrl, setQuiltImageUrl] = useState('');  // New state to hold the quilt image URL
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false); // New state for loading
    const [previousLayoutType, setPreviousLayoutType] = useState(''); // Track the previous layout type
    // Add this state to store the existing session ID
    const [existingSessionId, setExistingSessionId] = useState(null);
   
   
    const quiltDisplayRef = useRef(null); // Reference for quilt display section
    
    const [rating, setRating] = useState(0); // User rating
    const [feedback, setFeedback] = useState(''); // User feedback
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

    const canvasRef = useRef(null); // Canvas reference

    const [isEditing, setIsEditing] = useState(false); // Toggle for edit mode
    const [quiltGrid, setQuiltGrid] = useState([]); // Grid layout for quilt tiles
    const [sessionId, setSessionId] = useState(null);

    const [isEditUnlocked, setIsEditUnlocked] = useState(false);

    const [generateDisabled, setGenerateDisabled] = useState(false);

    const { currentUser } = useAuth(); // Get currentUser from context

    const [isResetModalOpen, setIsResetModalOpen] = useState(false);

    const [isResetLoading, setIsResetLoading] = useState(false);

    const [originalQuiltGrid, setOriginalQuiltGrid] = useState([]); // Original grid layout for revert
    const [isProcessingImages, setIsProcessingImages] = useState(false);
    const [processingError, setProcessingError] = useState(null);
    const [showThankYouMessage, setShowThankYouMessage] = useState(false);
    const [shareLink, setShareLink] = useState("");
    const [showModal, setShowModal] = useState(false);



    // Function to toggle the reset modal
    const openResetModal = () => setIsResetModalOpen(true);
    const closeResetModal = () => setIsResetModalOpen(false);
    const [locationData, setLocationData] = useState(null); // Stores geolocation data





    const saveSessionData = async () => {
        if (!currentUser || !currentUser.uid) return;
    
        const sessionRef = doc(db, 'sessions', currentUser.uid);
        const sessionData = {
            uploadedImages, // Save the array of uploaded image URLs
            gridSize,
            quiltImageUrl,
            quiltGrid, // Store quilt layout data if it has been generated
            generated
        };
    
        try {
            await setDoc(sessionRef, sessionData, { merge: true });
            console.log("Session data saved to Firestore.");
        } catch (error) {
            console.error("Error saving session data to Firestore:", error);
        }
    };

    useEffect(() => {
        const restoreSessionData = async () => {
            if (!currentUser || !currentUser.uid) return;
    
            const sessionRef = doc(db, 'sessions', currentUser.uid);
            const sessionSnap = await getDoc(sessionRef);
    
            if (sessionSnap.exists()) {
                const sessionData = sessionSnap.data();
                console.log("Restoring session data from Firestore:", sessionData);
    
                setGridSize(sessionData.gridSize || '');
                setUploadedImages(sessionData.uploadedImages || []);
                setQuiltImageUrl(sessionData.quiltImageUrl || '');
                setQuiltGrid(sessionData.quiltGrid || []);
                setGenerated(sessionData.generated || false);
                setFeedbackSubmitted(sessionData.feedbackSubmitted || false);
            }
        };
    
        restoreSessionData();
    }, [currentUser]);

    
    useEffect(() => {
        if (generated) { // Save only after generating the quilt
            saveSessionData();
        }
    }, [uploadedImages, gridSize, quiltGrid]);

    


   



  


    // Function to toggle edit mode
    const handleEditClick = () => {
        setOriginalQuiltGrid([...quiltGrid]);
        setIsEditing(true); // Enter edit mode
    };

    const checkTileUploadStatus = async () => {
        const sessionId = currentUser.uid;
        const sessionRef = doc(db, 'sessions', sessionId);
        const sessionDoc = await getDoc(sessionRef);
    
        if (sessionDoc.exists()) {
            const sessionData = sessionDoc.data();
            const totalTilesUploaded = sessionData?.total_tiles_uploaded || 0;
            return totalTilesUploaded >= requiredImages;
        }
        return false;
    };
    
    const waitForAllTilesToUpload = async () => {
        const maxRetries = 10;
        let retries = 0;
        while (retries < maxRetries) {
            const allTilesUploaded = await checkTileUploadStatus();
            if (allTilesUploaded) return true;
            await new Promise((resolve) => setTimeout(resolve, 6000)); 
            retries += 1;
        }
        return false;
    };
    

  



// Restore saved state from localStorage when component mounts
useEffect(() => {
    const savedState = JSON.parse(sessionStorage.getItem("quiltState"));
    if (savedState) {
        console.log("Restoring state from sessionStorage:", savedState);
        setGridSize(savedState.gridSize || '')
        setUploadedImages(savedState.uploadedImages || []);
        setQuiltImageUrl(savedState.quiltImageUrl || '');
        setQuiltGrid(savedState.quiltGrid || []);
        setIsEditUnlocked(savedState.isEditUnlocked || false);
        setGenerateDisabled(savedState.generateDisabled || false); // Restore generation status
        

        // Disable Generate button if previously generated
   

        console.log("isEditUnlocked after restoration:", savedState.isEditUnlocked);
        sessionStorage.removeItem("quiltState"); // Optional: clear after using
    }
}, []);













    

    useEffect(() => {
        console.log("useEffect triggered: quiltGrid =", quiltGrid);
        if (quiltGrid.length > 0) {
            drawOnCanvas(quiltGrid);
        }
    }, [quiltGrid]);
    
    const handleSaveChanges = (updatedGrid) => {
        console.log("Saving changes with updatedGrid:", updatedGrid);
        setQuiltGrid(updatedGrid);
        setIsEditing(false);
    
        setTimeout(() => {
            drawOnCanvas(updatedGrid);
        }, 0);
    };

    const handleCancelEdit = () => {
        setQuiltGrid([...originalQuiltGrid]); // Revert to original layout
        setIsEditing(false); // Exit edit mode without saving
    };

    useEffect(() => {
        if (currentUser && !sessionId) {
            setSessionId(currentUser.uid);
            setExistingSessionId(currentUser.uid); // Synchronize with existingSessionId
        }
    }, [currentUser, sessionId]);
    
    
    

    



    

    

    
    // Scroll to quilt display section after generation
// Scroll to quilt display section after generation with offset
// useEffect to scroll down after quiltImageUrl is set and loading is false
useEffect(() => {
    if (quiltImageUrl && !loading) {
        // Adding a small delay to ensure the quilt is rendered before scrolling
        setTimeout(() => {
            scrollToQuilt();
        }, 100); // Short delay to ensure rendering is complete
    }
}, [quiltImageUrl, loading]);

// Scroll function
const scrollToQuilt = () => {
    if (quiltDisplayRef.current) {
        const offset = -50; // Adjust offset if needed
        const topPosition = quiltDisplayRef.current.getBoundingClientRect().top + window.scrollY + offset;
        window.scrollTo({ top: topPosition, behavior: 'smooth' });
    }
};


const drawOnCanvas = (grid) => {
    const canvas = canvasRef.current;
    if (!canvas) {
        console.error("Canvas element not found.");
        return;
    }

    // Ensure `grid` is an array before proceeding
    if (!Array.isArray(grid)) {
        console.error("Invalid grid format. Expected an array but received:", grid);
        return;
    }

    const ctx = canvas.getContext("2d");
    const [columns, rows] = gridSize.split('x').map(Number); // Parse columns and rows correctly

    // Dynamic tile size based on the screen width
    const maxWidth = Math.min(window.innerWidth - 50, 600); // Adjust based on your layout
    const tileSize = Math.floor(maxWidth / columns);

    const borderSize = 10; // Thickness of the border
    const textHeight = 40; // Space for the text

    // Set canvas dimensions dynamically
    canvas.width = columns * tileSize + 2 * borderSize;
    canvas.height = rows * tileSize + 2 * borderSize + textHeight;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw the border and background
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Draw the quilt tiles
    grid.forEach((tile, index) => {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.src = tile.url;

        img.onload = () => {
            const x = (index % columns) * tileSize + borderSize;
            const y = Math.floor(index / columns) * tileSize + borderSize;
            ctx.drawImage(img, x, y, tileSize, tileSize);
        };

        img.onerror = (error) => {
            console.error("Failed to load image:", error);
        };
    });

    // Add the footer with logo and text
    const logo = new Image();
    logo.crossOrigin = "anonymous";
    logo.src = logoSrc; // Update to your correct logo path

    logo.onload = () => {
        const logoWidth = 22;
        const logoHeight = 22;

        // Calculate the total width of the logo and text
        const text = "Generated with NOSTAi";
        ctx.font = "bold 16px Arial";
        const textWidth = ctx.measureText(text).width;
        const totalWidth = logoWidth + 10 + textWidth; // Logo + padding + text

        // Position the logo on the right side
        const logoX = canvas.width - totalWidth - 10; // 10px padding from the right edge
        const logoY = canvas.height - textHeight + (textHeight - logoHeight) / 2;

        // Draw the logo
        ctx.drawImage(logo, logoX, logoY, logoWidth, logoHeight);

        // Position the text to the right of the logo
        ctx.fillStyle = "black";
        ctx.textAlign = "left"; // Align text to the left of its x-coordinate
        const textX = logoX + logoWidth + 10; // 10px padding between logo and text
        const textY = canvas.height - textHeight / 2 + 6; // Adjust for vertical alignment
        ctx.fillText(text, textX, textY);
    };

    logo.onerror = (error) => {
        console.warn("Failed to load logo:", error);

        // Fallback: render text without logo
        ctx.fillStyle = "black";
        ctx.font = "bold 16px Arial";
        ctx.textAlign = "right"; // Align text to the right
        const textX = canvas.width - 10; // Position text close to the right edge
        const textY = canvas.height - textHeight / 2 + 6; // Adjust for vertical alignment
        ctx.fillText("Generated with Nostai", textX, textY);
    };
};



    
    
    
    
    



  
    
    




    useEffect(() => {
        if (loading) {
            scrollToQuilt();  // Scroll down when loading starts
        }
    }, [loading]);
    useEffect(() => {
        // Scroll to the top of the page when QuiltBuilder is loaded
        window.scrollTo(0, 0);
      }, []);



      const handleDownloadClick = async () => {
        const canvas = canvasRef.current;
        if (!canvas) {
            console.error("Canvas element not found.");
            return;
        }
    
        // Convert canvas to image blob
        const imageUrl = canvas.toDataURL("image/jpeg");
        const blob = await (await fetch(imageUrl)).blob(); // Convert base64 to Blob
    
        const currentDate = new Date();
        const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
        const fileName = `Nostai_QuiltLayout_${gridSize}_${formattedDate}.jpg`;
    
        // Store the quilt in Firebase Storage
        if (currentUser && currentUser.uid) {
            const storageRef = ref(storage, `user_quilts/${currentUser.uid}/${fileName}`);
            try {
                await uploadBytes(storageRef, blob); // Upload the blob to Firebase Storage
                console.log(`Quilt saved to Firebase Storage: user_quilts/${currentUser.uid}/${fileName}`);
            } catch (error) {
                console.error("Error uploading quilt to Firebase Storage:", error);
                return; // Exit if upload fails
            }
        } else {
            console.error("User not authenticated. Unable to save quilt.");
            return; // Exit if no user is logged in
        }
    
        // Allow the user to download the quilt locally
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = fileName;
        link.click();
    };
    
    const handleShareClick = async () => {
        setShowModal(true); // Show modal
        setShareLink(""); // Indicate loading state
    
        const canvas = canvasRef.current;
        if (!canvas) {
            console.error("Canvas not found");
            setShowModal(false);
            return;
        }
    
        try {
            // Convert canvas to an image blob
            const imageUrl = canvas.toDataURL("image/jpeg");
            const blob = await (await fetch(imageUrl)).blob();
    
            const designId = `${currentUser.uid}-${uuidv4()}`;
            const storageRef = ref(storage, `shared_quilts/${designId}.jpg`);
            await uploadBytes(storageRef, blob);
            const publicUrl = await getDownloadURL(storageRef);
    
            // Save to Firestore
            await setDoc(doc(db, "shared_quilts", designId), {
                quiltImageUrl: publicUrl,
                userId: currentUser.uid,
                createdAt: new Date(),
            });
    
            // Set the sharable link
            const shareableLink = `https://nostai.com/c/${designId}`;
            setShareLink(shareableLink);
        } catch (error) {
            console.error("Error sharing quilt:", error);
            setShareLink("Error creating link");
        }
    };
    
    
    
    
    


    // Function to calculate required images based on grid size
    const calculateRequiredImages = (gridSize) => {
        switch (gridSize) {

            case "4x4":
                return 16;
            case "4x5":
                return 20;
            case "4x6":
                return 24;
            case "4x7":
                return 28;
            case "5x5":
                return 25;
            case "5x6":
                return 30;
            case "5x7":
                return 35;
            case "6x6":
                return 36;
            case "6x7":
                return 42;
            case "7x7":
                return 49;
            default:
                return 0; // Fallback for cases where no grid is selected
        }
    };

    
    const handleImageUpload = async (event) => {
        setIsProcessingImages(true); // Start processing state
        setProcessingError(null); // Clear previous errors
        const files = Array.from(event.target.files);
    
        if (uploadedImages.length + files.length > requiredImages) {
            setShowWarning(`You can only upload ${requiredImages} T-shirts for the ${gridSize} grid.`);
            setIsProcessingImages(false);
            return;
        }
    
        if (!sessionId) {
            setSessionId(currentUser.uid); // Generate sessionId if not set
        }
    
        const newUploadedImages = await Promise.all(
            files.map(async (file) => {
                try {
                    // Upload to Firebase and get URL
                    const uniqueFileName = `${uuidv4()}_${file.name}`;
                    const storageRef = ref(storage, `quilt_designs/${sessionId}/${uniqueFileName}`);
                    await uploadBytes(storageRef, file);
                    const tshirtUrl = await getDownloadURL(storageRef);
    
                    // Immediately add the image to the displayed list with `processed: false`
                    const uploadedFile = { url: tshirtUrl, path: storageRef.fullPath, processed: false };
                    setUploadedImages((prevImages) => [...prevImages, uploadedFile]);
    
                    // Send the image to the backend for processing
                    const formData = new FormData();
                    formData.append('session_id', sessionId);
                    formData.append('tshirt_url', tshirtUrl);
                    formData.append('image', file);
    
                    const response = await fetch('https://my-fastapi-app-289455571528.us-central1.run.app/process_image', {
                        method: 'POST',
                        body: formData,
                    });
    
                    const result = await response.json();
                    if (response.ok) {
                        console.log(`Processed image ${file.name} successfully.`);
                        // Update the uploaded image's `processed` status in state
                        setUploadedImages((prevImages) =>
                            prevImages.map((img) =>
                                img.url === tshirtUrl ? { ...img, processed: true } : img
                            )
                        );
                    } else {
                        console.error(`Failed to process image ${file.name}:`, result.error);
                        setProcessingError(`Failed to process image ${file.name}`);
                    }
                    return uploadedFile;
                } catch (error) {
                    console.error(`Error during processing for ${file.name}:`, error);
                    setProcessingError(`Error processing image ${file.name}`);
                    return null;
                }
            })
        );
    


        // Check if all images in the updated state have been processed
        const checkAllProcessed = () => {
            setUploadedImages((prevImages) => {
                const allProcessed = prevImages.every((img) => img.processed);
                setIsProcessingImages(!allProcessed); // Set to false if all are processed
                return prevImages; // Return the current state to avoid unnecessary updates
            });
        };
    
        // Delay checkAllProcessed slightly to ensure all updates are applied
        setTimeout(checkAllProcessed, 100);
    };
    
    
    
    
    // Handle grid size change
    const handleGridSizeChange = (event) => {
        if (generated) {
            // Prevent grid size change after quilt generation and prompt user to reset
            setShowWarning('Grid size cannot be changed after quilt generation. Please reset to change the grid size.');
            return; // Prevent further execution if quilt is already generated
        }

        const selectedGridSize = event.target.value;
        setGridSize(selectedGridSize);
        const calculatedImages = calculateRequiredImages(selectedGridSize);
        setRequiredImages(calculatedImages);

        logEvent(analytics, 'select_grid_size', { grid_size: selectedGridSize });

        // Allow user to upload more images if new grid size requires more
        if (uploadedImages.length < calculatedImages) {
            setShowWarning(`Please upload ${calculatedImages - uploadedImages.length} more T-shirts for the ${selectedGridSize} grid.`);
        } else if (uploadedImages.length === calculatedImages) {
            setShowWarning(''); // No warning if the exact number of images is already uploaded
        } else {
            setShowWarning(`You have uploaded more than required. You can only upload ${calculatedImages} T-shirts for the ${selectedGridSize} grid.`);
        }

        setGenerated(false); // Reset the generation flag if the grid size is changed
    };



    // Handle image upload click
    const handleUploadClick = (e) => {
        if (!gridSize ) {
            e.preventDefault(); // Prevent the file picker from opening
            setShowWarning('Please select grid size before uploading images.');
        } else if (uploadedImages.length >= requiredImages) {
            e.preventDefault(); // Prevent file picker if user has uploaded the maximum number of images
            setShowWarning(`You can only upload ${requiredImages} T-shirts for the ${gridSize} grid.`);
        } else {
            setShowWarning(''); // Hide warning if everything is selected
        }
    };

    const handleGenerateClick = async () => {
        if (uploadedImages.length < requiredImages) {
            setShowWarning(`Please upload exactly ${requiredImages} T-shirts for the ${gridSize} grid before generating.`);
        } else if (!gridSize) {
            setShowWarning('Please select grid size before generating.');
        } else if (isProcessingImages || processingError) {
            setShowWarning(processingError ? processingError : 'Processing images, please wait...');
        } else {
            setGenerated(true);
            setLoading(true); // Start loading state
            setShowWarning('');
            setGenerateDisabled(true); // Disable Generate button
        
            try {
                // Wait for all tiles to be uploaded before generating the quilt
                const allTilesUploaded = await waitForAllTilesToUpload();
                if (!allTilesUploaded) {
                    setShowWarning("Not all images have been processed. Please try again.");
                    setLoading(false);
                    //setGenerateDisabled(false);
                    return;
                }
        
                const payload = {
                    session_id: currentUser.uid,  // Use the userId as sessionId
                    grid_size: gridSize,
                    layout_type: "Random",
                };
        
                const apiRequest = fetch('https://my-fastapi-app-289455571528.us-central1.run.app/generate_quilt', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                }).then(response => response.json());
        
                const delay = new Promise(resolve => setTimeout(resolve, 3000));
                const [result] = await Promise.all([apiRequest, delay]);
        
                if (result && result.quilt_image_url) {
                    console.log('Quilt generated successfully:', result);
                    setQuiltImageUrl(result.quilt_image_url);
                    setQuiltGrid(result.tile_urls);
                    //await saveSessionData();
                } else {
                    const errorMessage = result.error || 'An unexpected error occurred while generating the quilt. Please reset and try again.';
                    console.error('Failed to generate quilt:', errorMessage);
                    setShowWarning(`Error generating quilt: ${errorMessage}`);
                }
            } catch (error) {
                console.error('Error during image upload or API request:', error);
                setShowWarning(`Error: ${error.message}`);
            } finally {
                setLoading(false); // End loading state after at least 3 seconds
            }
        }
    };
    
    
    
    const handleDeleteImage = async (index) => {
        const imageToDelete = uploadedImages[index];
    
        if (!imageToDelete) {
            console.warn("Image not found at the specified index.");
            return;
        }
    
        try {
            // Step 1: Access Firestore to find and delete associated tile
            if (currentUser && currentUser.uid) {
                const sessionRef = doc(db, 'sessions', currentUser.uid);
                const sessionSnap = await getDoc(sessionRef);
    
                if (sessionSnap.exists()) {
                    const sessionData = sessionSnap.data();
    
                    // Find the tile associated with this T-shirt image using `tshirt_url`
                    const tileToDelete = sessionData.tile_list.find(
                        (tile) => tile.tshirt_url === imageToDelete.url
                    );
    
                    if (tileToDelete) {
                        // Step 2: Update Firestore by removing the tile from `tile_list`
                        const updatedTileList = sessionData.tile_list.filter(
                            (tile) => tile.url !== tileToDelete.url
                        );
    
                        await updateDoc(sessionRef, {
                            tile_list: updatedTileList,
                            total_tiles_uploaded: updatedTileList.length,
                        });
                        console.log("Updated Firestore document successfully.");
    
                        // Step 3: Update local state immediately
                        setUploadedImages((prevImages) => prevImages.filter((_, i) => i !== index));
                        console.log("T-shirt image removed from local state.");
    
                        // Step 4: Delete the associated tile from Firebase Storage
                        const tilePath = tileToDelete.url.split(".appspot.com/")[1];
                        const decodedTilePath = decodeURIComponent(tilePath);
                        console.log("Decoded tile path:", decodedTilePath);
    
                        try {
                            const tileRef = ref(storage, decodedTilePath);
                            await deleteObject(tileRef);
                            console.log(`Deleted associated tile from Firebase Storage: ${decodedTilePath}`);
                        } catch (error) {
                            if (error.code === 'storage/object-not-found') {
                                console.warn(`Tile not found in Firebase Storage: ${decodedTilePath}`);
                            } else {
                                throw error;
                            }
                        }
                    } else {
                        console.warn("No associated tile found for the deleted T-shirt.");
                    }
                } else {
                    console.warn("No session data found in Firestore for this user.");
                }
            }
    
            // Step 5: Delete the T-shirt image from Firebase Storage
            if (imageToDelete.path) {
                const storageRef = ref(storage, imageToDelete.path);
                await deleteObject(storageRef);
                console.log(`Successfully deleted T-shirt image from Firebase Storage: ${imageToDelete.path}`);
            }
        } catch (error) {
            console.error("Error deleting T-shirt image and tile:", error);
        }
    };
    
    
    
    
    
    
    


    // Handle clicking the disabled generate button (fake button overlay)
    const handleDisabledGenerateClick = () => {
        if (!gridSize) {
            setShowWarning('Please select grid size before generating.');
        } else if (uploadedImages.length < requiredImages) {
            setShowWarning(`Please upload exactly ${requiredImages} T-shirts for the ${gridSize} grid before generating.`);
        }
    };



    const confirmReset = async () => {
        setIsResetLoading(true);
        try {
            // Clear all images under the session folder in Firebase Storage
            if (sessionId) {
                const storageRef = ref(storage, `quilt_designs/${sessionId}/`);
                const listResult = await listAll(storageRef); // List all items under the session folder
                const deletePromises = listResult.items.map((itemRef) => deleteObject(itemRef));
                await Promise.all(deletePromises);
                console.log(`Deleted all images from Firebase Storage under quilt_designs/${sessionId}/`);
            }
    
            // Clear session data from Firestore if needed
            if (sessionId) {
                const sessionRef = doc(db, 'sessions', sessionId);
                await deleteDoc(sessionRef);
                console.log(`Deleted session data from Firestore: ${sessionId}`);
            }
    
            // Reset local state
            setUploadedImages([]); // Clear uploaded images
            setGridSize(''); // Reset grid size
            setGenerated(false); // Reset the generated flag
            setRequiredImages(0); // Reset required images
            setShowWarning(''); // Clear any warnings
            setQuiltImageUrl(''); // Clear the quilt image URL
            setPreviousLayoutType(''); // Reset previous layout type
            setGenerateDisabled(false); // Re-enable Generate button
            setIsEditUnlocked(false); // Reset edit unlock state
            setQuiltGrid([]); // Clear quilt grid data
            setSessionId(null); // Clear session ID if relevant
            setFeedbackSubmitted(false); // Reset feedback submission state
            setRating(0); // Reset rating if needed
    
            // Clear session storage to remove any saved state
            sessionStorage.removeItem("quiltState");
    
            console.log("Reset everything to default.");
        } catch (error) {
            console.error("Error during reset:", error);
        }
        setIsResetLoading(false);
        closeResetModal(); // Close the modal
    };
    
    
    
    

    const handleSubmitFeedback = async () => {
        if (!currentUser || !currentUser.uid || !currentUser.email) {
            console.error("User not logged in, user ID, or email not available.");
            return;
        }
    
        try {
            const feedbackData = {
                userId: currentUser.uid, // Include the user ID
                email: currentUser.email, // Include the user's email
                rating: rating,
                timestamp: new Date(),
            };
    
            await addDoc(collection(db, "user_feedback"), feedbackData); // Save feedback to Firestore
            setFeedbackSubmitted(true);
            const sessionRef = doc(db, 'sessions', currentUser.uid);
            await updateDoc(sessionRef, { feedbackSubmitted: true });
            setShowThankYouMessage(true);

            // Hide the message after a short delay
            setTimeout(() => {
                setShowThankYouMessage(false);
            }, 5000);
        } catch (error) {
            console.error("Error submitting feedback:", error);
        }
    };
    


    

    return (
        <QuiltBuilderWithLayout handleReset={openResetModal} isGenerated={quiltImageUrl}>
            <div className="flex flex-col w-full min-h-screen bg-[#F6E8E0]">
    
                {/* Main Content Area */}
                <div className="flex flex-col items-center w-full overflow-y-auto p-8">
                    {/* Top bar containing centered title and reset button */}
                    <div className="flex flex-col items-center w-full overflow-auto p-8 relative">
                    <div
    className="text-xl sm:text-2xl font-semibold text-gray-800 text-center sm:text-left break-words"
    style={{ fontFamily: "Playfair Display" }}
>
    Craft your memory quilt.
</div>


                    </div>
    
                    {/* Reset Confirmation Modal */}
                    {isResetModalOpen && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                                <h2 className="text-lg font-semibold" style={{ fontFamily: "Playfair Display" }}>Start New Project?</h2>
                                <p className="mt-2 text-gray-600" style={{ fontFamily: "Playfair Display" }}>
                                    Your current design will be lost. Please make sure to download your design before starting a new project.
                                </p>
                                <div className="mt-4 flex justify-end space-x-4">
                                    <button
                                        className="px-4 py-2 bg-gray-200 rounded-md text-gray-700 hover:bg-gray-300"
                                        onClick={closeResetModal} style={{ fontFamily: "Playfair Display" }}
                                    >
                                        Cancel
                                    </button>
                                    <button
        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 flex items-center justify-center"
        onClick={confirmReset}
        disabled={isResetLoading} // Disable button when loading
    >
        {isResetLoading ? (
            <>
                <span className="spinner"></span> {/* Spinner element */}
                Loading...
            </>
        ) : (
            "Continue"
        )}
    </button>
                                </div>
                            </div>
                        </div>
                    )}
    
                    {/* Grid Size Section */}
<div className="flex justify-center w-full mb-8 ">
    <div className="bg-white w-full sm:w-[400px] p-4 rounded-lg shadow-lg bg-zinc-300">
        <div className="bg-gray-100 p-4 rounded-lg  ">
            <div className="flex flex-col items-center ">
                <label className="text-sm font-bold mb-1" style={{ fontFamily: "Playfair Display" }}>Grid Size</label>
                <select
                    value={gridSize}
                    onChange={handleGridSizeChange}
                    className="border rounded-lg p-2 text-gray-800 bg-white shadow-md"
                    disabled={generated}
                >
                    <option value="" disabled>Select</option>
                    <option value="4x4">4x4 (16 T-shirts)</option>
                    <option value="4x5">4x5 (20 T-shirts)</option>
                    <option value="4x6">4x6 (24 T-shirts)</option>
                    <option value="4x7">4x7 (28 T-shirts)</option>
                    <option value="5x5">5x5 (25 T-shirts)</option>
                    <option value="5x6">5x6 (30 T-shirts)</option>
                    <option value="5x7">5x7 (35 T-shirts)</option>
                    <option value="6x6">6x6 (36 T-shirts)</option>
                    <option value="6x7">6x7 (42 T-shirts)</option>
                    <option value="7x7">7x7 (49 T-shirts)</option>
                </select>
            </div>
        </div>
    </div>
</div>

    
                    {/* Warning message */}
                    {showWarning && (
                        <div className="mb-4 text-red-600">
                            {showWarning}
                        </div>
                    )}
    
                    {/* Notify user to upload images */}
                    {!showWarning && gridSize && uploadedImages.length < requiredImages && (
                        <div className="mb-4 text-blue-600" style={{ fontFamily: "Playfair Display" }}>
                            Please upload {requiredImages - uploadedImages.length} more T-shirts for the {gridSize} grid.
                        </div>
                    )}
    
                    {/* Image Upload Container and Generate Button */}
                    <div className="flex flex-col items-center bg-white border border-gray-300 p-6 rounded-lg shadow-lg w-full sm:w-[700px]">
                        {/* Upload Icon and file input */}
{!generated && !loading && (
    <label className="flex flex-col items-center justify-center cursor-pointer ">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12 text-gray-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 10h4M3 6h4m4 0h6M3 14h4m4 0h6m0 0v5a2 2 0 01-2 2H8a2 2 0 01-2-2v-5m0-4h4m4 0h4"
            />
        </svg>
        <span className="mt-2 text-sm text-gray-600 " style={{ fontFamily: "Playfair Display" }}>
            Upload T-shirts ({uploadedImages.length}/{requiredImages})
        </span>
        <input
            type="file"
            multiple
            ref={fileInputRef}
            onClick={handleUploadClick}
            onChange={handleImageUpload}
            className="hidden"
        />
    </label>
)}

    
                        <div className="w-full border-t border-gray-300 my-4"></div>
    
                        <div className="mt-4 w-full h-32 overflow-y-auto grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 gap-4">
                            {uploadedImages.map((image, index) => (
                                <div key={index} className="relative w-24 h-24 p-1">
                                    <img
                                        src={image.url}
                                        alt={`Uploaded ${index}`}
                                        className="w-full h-full object-cover rounded-lg"
                                    />
                                    {!image.processed && (
                                        <div className="absolute inset-0 flex items-center justify-center bg-gray-200 bg-opacity-50" style={{ fontFamily: "Playfair Display" }}>
                                            <span className="text-blue-500">Processing...</span>
                                        </div>
                                    )}
                                    {!generated && !loading && (
                                        <button
                                            className="absolute top-1 right-1 bg-red-500 text-white text-xs w-6 h-6 flex items-center justify-center rounded-full"
                                            onClick={() => handleDeleteImage(index)}
                                        >
                                            X
                                        </button>
                                    )}
                                </div>
                            ))}
                        </div>
    
                {/* Generate Button next to image upload container */}
{!generated && (
    <div className="relative flex justify-center mt-4">
        <button
            onClick={handleGenerateClick}
            className={`bg-blue-500 text-white px-6 py-3 rounded-lg ${
                isProcessingImages || generateDisabled || loading || uploadedImages.length < requiredImages || !gridSize || uploadedImages.some(img => !img.processed)
                    ? 'opacity-50 cursor-not-allowed'
                    : 'hover:bg-blue-600'
            }`}
            disabled={
                isProcessingImages || generateDisabled || loading || uploadedImages.length < requiredImages || !gridSize || uploadedImages.some(img => !img.processed)
            }
        >
            {isProcessingImages ? 'Processing...' : loading ? 'Generating Design...' : 'Generate Design'}
        </button>

        {processingError && (
            <div className="text-red-600 mt-2">{processingError} Please reset the project and try again. </div>
        )}

        {/* Overlay only if the button is disabled */}
        {(uploadedImages.length < requiredImages || !gridSize) && (
            <div
                className="absolute inset-0 flex items-center justify-center cursor-pointer"
                onClick={handleDisabledGenerateClick}
                style={{ zIndex: 10, backgroundColor: 'rgba(0, 0, 0, 0)' }} // Transparent overlay
            ></div>
        )}
    </div>
)}

                    </div>
    
                    <div ref={quiltDisplayRef} className="flex flex-col items-center justify-center w-full mt-8 ">
                        <div className="bg-gray-100 p-4 rounded-lg shadow-lg w-full sm:w-[500px] md:w-[600px] flex flex-col items-center border-4 border-zinc-300">
                            
                            {/* Main Content */}
                            {loading ? (
                                <img src={loadingGif} alt="Loading..." className="w-16 h-16" />
                            ) : quiltImageUrl ? (
                                <>
                                    {/* Toggle between Quilt Display and QuiltEditor */}
                                    {isEditing ? (
                                        <QuiltEditor quiltGrid={quiltGrid} gridSize={gridSize} onSave={handleSaveChanges} onCancel={handleCancelEdit} />
                                    ) : (
                                        <>
                                            {/* Canvas Element */}
                                            <div className="quilt-container max-w-full overflow-x-auto flex justify-center items-center">
    <canvas ref={canvasRef} className="max-w-full max-h-full"></canvas>
</div>

    
{/* Action Buttons */}
<div className="flex flex-wrap space-y-2 sm:space-y-0 sm:space-x-4 mt-4 justify-center sm:justify-start">
    <button
        onClick={handleEditClick}
        className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 w-full sm:w-auto"
        style={{ fontFamily: "Playfair Display" }}
    >
        Edit Quilt
    </button>
    <button
        onClick={handleDownloadClick}
        className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 w-full sm:w-auto"
        style={{ fontFamily: "Playfair Display" }}
    >
        Download Design
    </button>
    <button
        onClick={handleShareClick}
        className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 w-full sm:w-auto"
        style={{ fontFamily: "Playfair Display" }}
    >
        Share Design
    </button>

    {/* Share Modal */}
    {showModal && <ShareModal shareLink={shareLink} setShowModal={setShowModal} />}
</div>



<a
  href="https://buymeacoffee.com/nostai"
  target="_blank"
  rel="noopener noreferrer"
  className="flex justify-center items-center h-10 text-white bg-orange-500 -ml-3 rounded-md border border-transparent px-4 py-2 text-base leading-6 shadow-sm hover:opacity-85 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 transition-all mt-4"
  style={{ fontFamily: "Playfair Display" }}
>
  <img
    src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg"
    alt="Buy me a coffee"
    className="h-5 w-5 mr-2"
  />
  Buy me a coffee
</a>


                                        </>
                                    )}
                                </>
                            ) : (
                                <span className="text-gray-500" style={{ fontFamily: "Playfair Display" }}>
  ✨ Your personalized quilt design will magically appear here! ✨
</span>

                            )}


    
                        
                        </div>

                            {/* Feedback Section */}
                            {quiltImageUrl && !feedbackSubmitted && (
                                <div className="flex flex-col items-center mt-8 mb-4">
                                    <div className="flex space-x-2 mb-2">
                                        {[1, 2, 3, 4, 5].map((star) => (
                                            <button
                                                key={star}
                                                onClick={() => setRating(star)}
                                                className={`text-2xl ${star <= rating ? 'text-yellow-400' : 'text-gray-300'}`}
                                            >
                                                ★
                                            </button>
                                        ))}
                                    </div>
                                    <button
                                        onClick={handleSubmitFeedback}
                                        className="bg-blue-500 text-white px-4 py-1 rounded-lg hover:bg-blue-600 mt-2" style={{ fontFamily: "Playfair Display" }}
                                    >
                                        Submit Feedback
                                    </button>
                                </div>
                            )}
                            {showThankYouMessage && (
                                <div className="text-green-500 mt-4" style={{ fontFamily: "Playfair Display" }}>
                                    Thank you for your feedback!</div>
                            )}


{quiltImageUrl && (
  <div className="mt-8 bg-gray-100 p-4 rounded-lg shadow-lg">
    <p
      className="text-xl font-bold mb-4 text-center"
      style={{ fontFamily: "Playfair Display" }}
    >
      Connect with a Quilter
    </p>
    {/* Options for Indian Users */}
    <div className="mb-8">
      <p className="text-lg font-semibold mb-2 text-center">For Indian Users</p>
      <div className="flex flex-col sm:flex-row items-center sm:items-stretch space-y-4 sm:space-y-0 sm:space-x-4 p-4 bg-white rounded-lg shadow max-w-md mx-auto">
        <img
          src={sweetroot} // Replace with Sweet Root logo URL
          alt="Sweet Root Logo"
          className="w-12 h-12 sm:w-16 sm:h-16 rounded-full mx-auto sm:mx-0"
        />
        <div className="text-center sm:text-left flex-1">
          <p className="text-lg font-medium">Sweet Root Memory Quilter</p>
          <p className="text-sm text-gray-600">Trusted partner for Indian users</p>
        </div>
        <button
          className="w-full sm:w-auto bg-blue-500 text-white px-4 py-2 sm:px-3 sm:py-1 text-sm sm:text-base rounded-lg hover:bg-blue-600"
          onClick={() =>
            window.open("https://www.sweetroot.in/quilt-t-shirt-form", "_blank")
          }
        >
          Get Started
        </button>
      </div>
    </div>
    {/* Options for USA Users */}
    <div>
      <p className="text-lg font-semibold mb-2 text-center">For USA Users</p>
      {/* MemoryStitch Option */}
      <div className="flex flex-col sm:flex-row items-center sm:items-stretch space-y-4 sm:space-y-0 sm:space-x-4 p-4 bg-white rounded-lg shadow max-w-md mx-auto mb-4">
        <img
          src={memorystitch} // Replace with MemoryStitch logo URL
          alt="MemoryStitch Logo"
          className="w-12 h-12 sm:w-16 sm:h-16 rounded-full mx-auto sm:mx-0"
        />
        <div className="text-center sm:text-left flex-1">
          <p className="text-lg font-medium">MemoryStitch</p>
        </div>
        <button
          className="w-full sm:w-auto bg-blue-500 text-white px-4 py-2 sm:px-3 sm:py-1 text-sm sm:text-base rounded-lg hover:bg-blue-600"
          onClick={() =>
            window.open(
              "https://memorystitch.refr.cc/default/u/nostai?s=sp&t=cp",
              "_blank"
            )
          }
        >
          Get Started
        </button>
      </div>
      {/* Project Repat Option */}
      <div className="flex flex-col sm:flex-row items-center sm:items-stretch space-y-4 sm:space-y-0 sm:space-x-4 p-4 bg-white rounded-lg shadow max-w-md mx-auto">
        <img
          src={project} // Replace with Project Repat logo URL
          alt="Project Repat Logo"
          className="w-12 h-12 sm:w-16 sm:h-16 rounded-full mx-auto sm:mx-0"
        />
        <div className="text-center sm:text-left flex-1">
          <p className="text-lg font-medium">Project Repat</p>
        </div>
        <button
          className="w-full sm:w-auto bg-blue-500 text-white px-4 py-2 sm:px-3 sm:py-1 text-sm sm:text-base rounded-lg hover:bg-blue-600"
          onClick={() =>
            window.open("https://www.projectrepat.com/amb?a=DRdbHRcP", "_blank")
          }
        >
          Get Started
        </button>
      </div>
    </div>
  </div>
)}







                    </div>
                </div>
            </div>
        </QuiltBuilderWithLayout>
    );
}    
export default QuiltBuilder;
