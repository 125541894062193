import React from 'react';
import testimonials1 from '../Assets/testimonials1.webp';
import testimonials2 from '../Assets/testimonials2.webp';

const Testimonials = () => {
  return (
    <section className="flex flex-col items-center gap-10 px-5 mt-10 w-full max-w-[1257px]">
      {/* Testimonials Container */}
      <div className="grid grid-cols-2 gap-10 justify-center w-full max-md:grid-cols-1">
        {/* Testimonial 1 */}
        <div className="flex flex-col items-center">
          <img
            loading="lazy"
            src={testimonials1}
            alt="Testimonial 1"
            className="object-contain w-full max-w-[480px] h-auto rounded-lg "
          />
        </div>

        {/* Testimonial 2 */}
        <div className="flex flex-col items-center">
          <img
            loading="lazy"
            src={testimonials2}
            alt="Testimonial 2"
            className="object-contain w-full max-w-[480px] h-auto rounded-lg "
          />
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
