import React from 'react';

const Footer = () => {
  return (
    <footer className="overflow-hidden px-10 py-10 w-full bg-zinc-300 max-md:px-5">
      <div className="flex flex-col gap-5 max-w-[1200px] mx-auto">
        {/* Top Section: Text */}
        <div style={{ fontFamily: "Playfair Display" }} className="text-lg tracking-wide text-black font-normal max-md:text-center">
          <p>
            Turn AI designs into artisan quilts & bring your memories to life.
            <br />
            Email us at <a href="mailto:info@nostai.com" className="underline" style={{ fontFamily: "Playfair Display" }}>info@nostai.com</a>.
          </p>
        </div>

        {/* Bottom Section: Aligned Links and Copyright */}
        <div style={{ fontFamily: "Playfair Display" }} className="flex items-center justify-between gap-5 text-base font-semibold max-md:flex-col max-md:items-center max-md:gap-3">
          {/* Copyright Text */}
          <span>© 2024 All Rights Reserved | beautgroup</span>

          {/* Navigation Links */}
          <nav className="flex gap-5" style={{ fontFamily: "Playfair Display" }}>
            <a href="/about" className="hover:underline">About</a>
            <a href="/terms-of-service" className="hover:underline">Terms</a>
            <a href="/privacy-policy" className="hover:underline">Privacy</a>
            <a href="/Contact" className="hover:underline">Contact</a>
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
