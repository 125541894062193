import React from 'react';
import logo from '../Assets/headerlogo.png';
import { useNavigate } from 'react-router-dom';


const Header = () => {
  const navigate = useNavigate();



const handleStartDesigningClick = () => {
  navigate('/sign-in'); // Redirects to the sign-in page
};
  return (
    <header className="flex items-center justify-between flex-wrap gap-5 py-6 px-6 w-full text-lg font-medium tracking-wide bg-zinc-300 text-black max-md:justify-center max-md:py-4 max-sm:flex-col max-sm:gap-3">
      {/* Logo */}
      <img
        loading="lazy"
        src={logo}
        alt="NOSTAi logo"
        className="w-50 h-10 mr-2"
      />

      {/* Button */}
      <button onClick={handleStartDesigningClick} style={{ fontFamily: "Playfair Display" }} className="gap-2.5 py-2 px-4 bg-[#F6E8E0] rounded-3xl border border-black text-sm max-md:px-3 max-sm:py-1 max-sm:text-xs">
        DESIGN YOUR QUILT
      </button>
    </header>
  );
};

export default Header;
