import React, { useState, useEffect } from "react";

function QuiltEditor({ quiltGrid, gridSize = "2x2", onSave, onCancel }) {
    const [editedGrid, setEditedGrid] = useState([...quiltGrid]); // Editable copy of the quilt grid
    const [originalGrid, setOriginalGrid] = useState([...quiltGrid]); // Original copy for reverting
    const [selectedTiles, setSelectedTiles] = useState([]); // Tracks selected tiles for swapping

    // Parse the gridSize (e.g., "7x7") to get columns and rows
    const [columns, rows] = (gridSize || "2x2").split("x").map(Number); // Fallback to "2x2" if gridSize is missing

    // Calculate dynamic tile size based on screen width and grid size
    const maxWidth = Math.min(window.innerWidth - 50, 600); // Maximum grid width
    const maxHeight = Math.min(window.innerHeight - 200, 700); // Maximum grid height (with padding)
    const tileSize = Math.floor(Math.min(maxWidth / columns, maxHeight / rows)); // Adjust tile size to fit

    // Update the grid states when quiltGrid changes (for dynamic updates)
    useEffect(() => {
        setEditedGrid([...quiltGrid]);
        setOriginalGrid([...quiltGrid]);
    }, [quiltGrid]);

    // Handle tile selection for swapping
    const handleTileSelect = (index) => {
        if (selectedTiles.length < 2) {
            setSelectedTiles([...selectedTiles, index]);
        }

        // Swap tiles if two are selected
        if (selectedTiles.length === 1) {
            const [firstIndex, secondIndex] = [selectedTiles[0], index];
            const newGrid = [...editedGrid];
            [newGrid[firstIndex], newGrid[secondIndex]] = [
                newGrid[secondIndex],
                newGrid[firstIndex],
            ];

            setEditedGrid(newGrid);
            setSelectedTiles([]); // Reset selected tiles
        }
    };

    // Save the modified quilt layout
    const handleSave = () => {
        onSave(editedGrid); // Pass the edited grid back to parent for saving
    };

    // Cancel changes and revert to the original layout
    const handleCancel = () => {
        setEditedGrid([...originalGrid]); // Revert back to the original layout
        if (onCancel) onCancel(); // Trigger onCancel if provided
    };

    return (
        <div
    className="bg-gray-100 p-4 rounded-lg shadow-lg flex flex-col items-center border-4 border-gray-300"
    style={{
        width: `${columns * tileSize + 40}px`, // Dynamically adjust width based on columns and tile size
        maxWidth: "90vw", // Ensure it doesn't exceed the viewport width
        height: `${rows * tileSize + 400}px`, // Dynamically adjust height based on rows and tile size
        maxHeight: "90vh", // Ensure it doesn't exceed the viewport height
        overflow: "hidden", // Prevent content overflow
    }}
>
            <h2 className="text-xl font-semibold mb-2">Edit Quilt</h2>

            {/* Instructional Message */}
            <p className="text-sm text-gray-500 mb-4">
                Click on tiles to swap positions
            </p>

            {/* Scrollable Grid Container */}
            <div
                className="grid gap-2 overflow-auto"
                style={{
                    maxHeight: "70vh", // Limit grid height to 70% of the viewport
                    gridTemplateColumns: `repeat(${columns}, ${tileSize * 0.8}px)`,
                    gridTemplateRows: `repeat(${rows}, ${tileSize * 0.8}px)`,
                }}
            >
                {editedGrid.map((tile, index) => (
                    <div
                        key={index}
                        className={`p-1  ${
                            selectedTiles.includes(index)
                                ? "border-4 border-blue-400"
                                : "border-4 border-gray-300 hover:border-blue-400"
                        }`}
                        onClick={() => handleTileSelect(index)}
                        style={{
                            width: `${tileSize * 0.8}px`,
                            height: `${tileSize * 0.8}px`,
                        }}
                    >
                        {tile?.url ? (
                            <img
                                src={tile.url}
                                alt={`Tile ${index}`}
                                className="w-full h-full object-cover rounded-md"
                            />
                        ) : (
                            <div className="w-full h-full bg-gray-200 rounded-md flex items-center justify-center">
                                Empty
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {/* Action Buttons */}
            <div className="flex space-x-4 mt-4">
                <button
                    onClick={handleSave}
                    className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
                >
                    Save
                </button>
                <button
                    onClick={handleCancel}
                    className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600"
                >
                    Cancel
                </button>
            </div>
        </div>
    );
}

export default QuiltEditor;
