import React from 'react';
import Header from './Header';
import HowItWorks from './HowItWorks';
import DesignQuilt from './DesignQuilt';
import Testimonials from './Testimonials';
import Statistics from './Statistics';
import Footer from './Footer';
import HeroSection from './HeroSection';
import ImageSection from './ImageSection';
import AboutSection from './AboutSection';


const Home = () => {
  return (
    <div className="flex flex-col items-center gap-10 w-full bg-[#F6E8E0]">
    <Header />
    <main className="w-full flex flex-col items-center gap-10 px-0 mx-0  py-0">

      <HeroSection />
      <HowItWorks />
      <DesignQuilt />
      <Testimonials />
      <Statistics />
      <ImageSection />
      <AboutSection />
    </main>
    <Footer />
  </div>
  
  );
};

export default Home;