import React from 'react';
import container from '../Assets/Container.png';
import sampleVideo from '../Assets/sample.mp4'; // Replace with your video file path

const HeroSection = () => {
  return (
    <section className="flex flex-col md:flex-row justify-center items-center gap-5 px-5 md:px-20 lg:px-60 mt-0 mb-0 w-full min-h-[400px] md:min-h-[500px]">
      {/* Left Container */}
      <div className="flex flex-col items-center md:items-start text-lg font-medium tracking-wide text-black w-full">
        <img
          loading="lazy"
          src={container}
          alt="NOSTAi logo"
          className="object-contain w-28 h-28 sm:w-36 sm:h-36 md:w-48 md:h-48 lg:w-60 lg:h-60 xl:w-80 xl:h-80"
        />
      </div>

      {/* Video Section */}
      <div className="flex items-center justify-center bg-[#F6E8E0] w-full sm:w-[300px] sm:h-[300px] md:w-[420px] md:h-[500px] lg:w-[600px] lg:h-[600px]">
        <video
          autoPlay
          loop
          muted
          className="object-contain w-full h-full"
        >
          <source src={sampleVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </section>
  );
};

export default HeroSection;
