import React from 'react';
import image1 from '../Assets/image1.png'; // Left image
import image2 from '../Assets/image2.png'; // Right image

const ImageSection = () => {
  return (
    <section className="w-full py-10 bg-[#F6E8E0] flex justify-center items-center">
      <div className="flex items-center justify-center gap-10 max-w-screen-xl flex-wrap">
        {/* Left Image */}
        <div className="flex justify-center w-full lg:w-1/3">
          <img
            loading="lazy"
            src={image1}
            alt="Quilt design showcase"
            className="object-contain w-full max-w-[500px] h-auto"
          />
        </div>

        {/* Center Text with White Background */}
        <div
          className="text-center bg-[#F6E8E0] py-10 px-5 rounded-lg text-lg leading-relaxed tracking-wide text-gray-800 w-full max-w-[250px]"
          style={{ fontFamily: "Playfair Display" }}
        >
          <p>The most universal</p>
          <p>language isn't spoken;</p>
          <p>it's woven into the</p>
          <p>fabric of memories.</p>
        </div>

        {/* Right Image */}
        <div className="flex justify-center w-full lg:w-1/3">
          <img
            loading="lazy"
            src={image2}
            alt="Quilt design process"
            className="object-contain w-full max-w-[500px] h-auto"
          />
        </div>
      </div>
    </section>
  );
};

export default ImageSection;
