import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './home/Home';
import QuiltBuilder from './components/QuiltBuilder';
import ShareableQuiltPage from './components/ShareableQuiltPage'; // Import the new ShareableQuiltPage component
import AboutUs from './components/AboutUs'; // Import the AboutUs component
import TermsOfService from './components/TermsOfService'; // Import TermsOfService
import PrivacyPolicy from './components/PrivacyPolicy'; // Import PrivacyPolicy
import Contact from './components/Contact';
import SignUp from './components/SignUp';
import { AuthProvider } from './components/AuthContext'; // Import the AuthProvider

// Google Analytics Tracking
const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'G-7MM9NQXCJP', {
        page_path: location.pathname + location.search,
      });
    }
  }, [location]);
};

function AppRoutes() {
  usePageTracking(); // Hook for tracking page views

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/QuiltBuilder" element={<QuiltBuilder />} />
      <Route path="/c/:designId" element={<ShareableQuiltPage />} /> {/* Add dynamic route for sharable links */}
      <Route path="/sign-in" element={<SignUp />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
}

function App() {
  return (
    <AuthProvider> {/* Wrap Routes with AuthProvider */}
      <Router>
        <AppRoutes />
      </Router>
    </AuthProvider>
  );
}

export default App;
